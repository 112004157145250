import {
    quotesGetDays, getStaggeredDiscount, quotesStaggeredRecalc, quotesStaggeredCalcOnProductChange,
} from '@/mixins/staggeredDiscounts'
import onRepairComplete from '@/mixins/repairs/repairMixin';
import { setChoiceItems, editChoiceItems } from '@/components/checklist/mixins/choiceitems';
import { setChecklistItems } from '@/components/checklist/mixins/data';

const custom_client = {
    default: {
        methods: {
            onRepairComplete,
            editChoiceItems,
        },
    },

    Editpane: {
    },

    Fields: {
        methods: {
            quotesGetDays,
            getStaggeredDiscount,
            quotesStaggeredRecalc,
            quotesStaggeredCalcOnProductChange,
            setChecklistItems,
            setChoiceItems,

            onUpdateBookingsLinesDays({ data }) {
                const days = quotesGetDays({ data: { mainItem: data.item } });
                const discount = this.getStaggeredDiscount(days);
                data.item.bookings_lines.forEach((group) => {
                    group.items.forEach((item) => {
                        if (item.days !== days) {
                            this.$set(item, 'days', days);
                            if (!item.volume_discount_locked) {
                                this.$set(item, 'volume_discount', discount);
                            }
                        }
                    });
                });
            },
        },
    },

    Rules: {
        mounted() {
            this.$on('changed', this.onRuleChanged);
        },

        beforeDestroy() {
            this.$off('changed', this.onRuleChanged);
        },

        methods: {
            onRuleChanged(payload = {}) {
                if (this.config.api.table === 'bookings') {
                    if (payload.action === 'add-line') {
                        // option and field are also available
                        this.$set(
                            payload.item,
                            'days',
                            quotesGetDays({ data: { mainItem: this.item } }),
                        );
                        if (payload.item.days && !payload.item.volume_discount_locked) {
                            this.$set(
                                payload.item,
                                'volume_discount',
                                this.getStaggeredDiscount(payload.item.days),
                            );
                        }
                    }
                }
            },

            getStaggeredDiscount,

            setChecklistItems,
        },
    },
};

export default custom_client;
